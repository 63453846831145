<template>
  <div>
    <el-card class="box-card" style="padding: 20px">
      <div style="margin-bottom: 30px"><i class="card-line"></i>
        {{ type === '0' ? '新建' : '编辑' }}公告
        <el-button type="warning" style="float: right" size="mini" @click="$router.go(-1)">返回</el-button>
      </div>
      <div style="margin: 15px 0">
        <span class="report-title">选择学校</span>
        <el-select v-model="selectSchool" placeholder="请选择" size="mini" @change="schoolChage">
          <el-option v-for="item in schoolList" :key="item.id" :label="item.schoolName" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div style="margin: 15px 0">
        <span class="report-title">公告标题</span>
        <el-input size="mini" style="width: 200px" v-model="valueName" placeholder="请输入公告标题"></el-input>
      </div>
      <wang-editor :value="valueEdit" :ref="valueDo" @valueChange="valueChange"></wang-editor>

      <el-button type="warning" style="margin: 30px 0" size="mini" @click="updataNotice">
        {{ type === '0' ? '发布公告' : '更新公告' }}
      </el-button>
    </el-card>
  </div>
</template>

<script>
import WangEditor from '@/components/WangEnduitTry/index.vue';
import { defineComponent, reactive, toRefs, ref, onMounted } from "vue";
import { addAnnouncement, announcementById, schoolList, update } from "../noticeApi";
import { useRoute, useRouter } from "vue-router";
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: "addNotice",
  components: {
    WangEditor,
  },
  setup() {
    let route = useRoute()
    let router = useRouter()
    let valueDo = ref()
    let data = reactive({
      valueName: '',
      valueEdit: '',
      selectSchool: '',
      schoolList: [],
      type: '0',
      action: false
    })
    onMounted(() => {
      if (route.query.id) {
        data.type = '1'
        getMyNotice()
      }
      getSchool()
    })
    let getMyNotice = () => {
      announcementById({ id: route.query.id, action: data.action }).then(res => {
        if (res.data.code === '200') {
          data.valueName = res.data.data.announcement.announcementName
          data.valueEdit = res.data.data.announcement.announcementContent
          data.selectSchool = res.data.data.announcement.schoolId
        }
      })
    }
    let getSchool = () => {
      schoolList({
        pageIndex: 1,
        pageSize: 1000,
      }).then(res => {
        if (res.data.code === '200') {
          let newRes = res.data.data
          data.schoolList = newRes.schoolPageInfo.list
        }
      })
    }
    let valueChange = (value) => {
      if (value) data.valueEdit = value
    }

    let updataNotice = () => {
      // if (!data.selectSchool) return ElMessage.warning('请先选择学校')
      if (!data.valueName) return ElMessage.warning('请输入公告标题')
      if (data.type === '0') {
        let parame = {
          "announcementName": data.valueName,
          "announcementContent": data.valueEdit,
          "publisher": JSON.parse(sessionStorage.getItem('user')).username,
          "schoolId": data.selectSchool
        }
        addAnnouncement(parame).then(res => {
          if (res.data.code === '200') {
            ElMessage.success(res.data.msg)
            router.push('/noticeManage')
          } else {
            ElMessage.error(res.data.msg)
          }
        })
      } else {
        let parame = {
          id: route.query.id,
          "announcementName": data.valueName,
          "announcementContent": data.valueEdit,
          "publisher": JSON.parse(sessionStorage.getItem('user')).username,
          "schoolId": data.selectSchool
        }
        update(parame).then(res => {
          if (res.data.code === '200') {
            ElMessage.success(res.data.msg)
            router.push('/noticeManage')
          } else {
            ElMessage.error(res.data.msg)
          }
        })
      }

    }
    let schoolChage = (val) => {
      data.selectSchool = val
    }
    return {
      ...toRefs(data),
      valueDo,
      valueChange,
      updataNotice,
      schoolChage,
    }
  }
})
</script>

<style scoped>
.card-line {
  display: inline-block;
  width: 3px;
  height: 13px;
  border-radius: 8px;
  margin-right: 10px;
  background: #faad14;
}

.report-title {
  margin-left: 20px;
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  margin-right: 10px;
}
</style>